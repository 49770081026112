import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DemoFooter from "../components/demoFooter"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found"/>

    <div className={"row my-5"}>
      <div className={"col text-center"}>
        <h1>NOT FOUND</h1>
      </div>
    </div>

    <DemoFooter/>
  </Layout>
)

export default NotFoundPage
